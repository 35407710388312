import React from "react"

import Layout from "components/layout"

import SEO from "components/seo"
import { graphql } from "gatsby"
import SetHtml from "components/sethtml"
import { Helmet } from "react-helmet"

export default ({ data }) => {
  const aboutHeader = data.aboutHead.edges[0].node.html
  return (
    <Layout>
      <SEO title="About IID - the personal website of Stephen Hawley, PhD" />
      <Helmet>
        <body className={`body-background`} />
      </Helmet>
      <div className="d-flex flex-column h-100">
        <div style={{ height: "10%" }}></div>
        <div className="container-fluid bg-white pt-3">
          <div className="row">
            <div className="col mx-auto py-3">
              <SetHtml content={aboutHeader} />
            </div>
          </div>
        </div>
        <div className="flex-grow-1 bg-white"></div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    aboutHead: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: "steve-about-head" } } }
    ) {
      edges {
        node {
          id
          html
        }
      }
    }
  }
`
